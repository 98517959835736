<template>
  <tr class="ifra-table-row">
    <td>
      <ProductThumb
        v-if="productId"
        :id="productId"
        :href="productLink"
        :name="productName"
        :src="productImage"
        :ga-category="gaCategory"
        layout="small"
      />
    </td>
    <td class="ifra-align-right">{{ previousRecommendation }}</td>
    <td class="ifra-align-right">{{ newRecommendation }}</td>
  </tr>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      fragrance() {
        return this.getIngredient("fragrance")
      },
      productId() {
        return this.fragrance?.value
      },
      productName() {
        return this.fragrance?.productName
      },
      productLink() {
        return this.fragrance?.productUrl
      },
      productImage() {
        return this.fragrance?.productImageUrl
      },
      gaCategory() {
        return this.fragrance?.gaCategory
      },
      previousRecommendation() {
        return this.getValue("previous_recommendation")
      },
      newRecommendation() {
        return this.getValue("new_recommendation")
      },
    },
  }
</script>

<style lang="scss" scoped>
  .ifra-table-row {
    td {
      padding-bottom: $space-s;
    }
  }
  .ifra-align-right {
    text-align: right;
  }
</style>
