<template>
  <Container>
    <Headline :level="2">Fragrance Recommendation</Headline>
    <table class="ifra-table">
      <thead class="ifra-table-header">
        <tr>
          <th>Fragrance</th>
          <th class="ifra-align-right">Previous:</th>
          <th class="ifra-align-right">New:</th>
        </tr>
      </thead>
      <tbody>
        <IfraTableRow
          v-for="element in element.nestedElements"
          :key="element.id"
          :element="element"
        />
      </tbody>
    </table>
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"
  import IfraTableRow from "~/alchemy/elements/ifra_table_row"

  export default {
    components: {
      IfraTableRow,
    },
    mixins: [AlchemyElement],
  }
</script>

<style lang="scss" scoped>
  .ifra-table {
    width: 100%;
    th {
      padding-bottom: $space-s;
      &:first-child {
        text-align: left;
      }
    }
  }
  .ifra-align-right {
    text-align: right;
  }
</style>
